"use client";

import Link from "next/link";
import styles from "./not-found.module.css";
import { Br } from "@/components/ui/br";
import { nbsp } from "@/components/ui/nbsp";

export default function NotFound() {
	return (
		<section className={`container ${styles.notFound}`}>
			<title>Страница не найдена</title>
			<h1>
				Страница <span>не{nbsp}найдена</span>
			</h1>
			<p>
				К сожалению, запрашиваемая <Br isMobile={true} /> страница не{nbsp}
				найдена.
				<Br isMobile={true} /> Возможно, вы{nbsp}ошиблись при{" "}
				<Br isMobile={true} />
				наборе
				<Br /> адреса или{nbsp}страница <Br isMobile={true} />
				была удалена.
			</p>
			<Link className={styles.backToMain} href={"/"}>
				Перейти на{nbsp}главную
			</Link>
		</section>
	);
}
